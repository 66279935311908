import React from 'react';
import { useState } from 'react';
import { Box, Button, CircularProgress, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import FullScreenBackground from '../FullScreenBackground/FullScreenBackground';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router';
import { useAPI } from '../../contexts/Api';
import VerifyInput from './VerifyInput/VerifyInput';
import InputFields from './InputFields/InputFields';


const SetNewPassword = ({ verify, withName, verifyCallback, emailInputCallback, email_input_button_text, verify_button_text }) => {
    const api = useAPI();
    const params = useParams();
    const theme = useTheme();
    const breakpointmatches = useMediaQuery(theme.breakpoints.up('md'));

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_validation, setPassword_validation] = useState("");

    const [name_empty, setName_empty] = useState(false);
    const [email_empty, setEmail_empty] = useState(false);
    const [passwordScore, setPasswordScore] = useState(0);
    const [password_too_weak, setPassword_too_weak] = useState(false);
    const [passwordNotTheSame, setPasswordNotTheSame] = useState(false);
    const [status, setStatus] = useState({ status: "start", msg: "" });
    const setFailed = (msg) => {
        setStatus({ status: "failed", msg: msg });
    };
    const setSucceeded = (msg) => {
        setStatus({ status: "succeeded", msg: msg });
    };
    const setStart = () => {
        setStatus({ status: "start", msg: status.msg });
    }
    const setWaiting = () => {
        setStatus({ status: "waiting", msg: "" });
    };

    const handleVerify = () => {
        if (verify) {
            if (password !== password_validation) {
                setPasswordNotTheSame(true);
            } else {
                setPasswordNotTheSame(false);
                if (withName) {
                    if (name === "") {
                        setName_empty(true);
                        return;
                    }
                    setName_empty(false);
                }
                if (passwordScore === 0) {
                    setPassword_too_weak(true);
                    return
                }
                setPassword_too_weak(false);
                setWaiting();
                verifyCallback(params.email_token, name, password, (msg) => {
                    setSucceeded(msg);
                }, (msg) => {
                    setFailed(msg);
                });
            }
        }
    };


    const handleEmailInput = () => {
        if (email === "") {
            setEmail_empty(true);
            return;
        }
        setEmail_empty(false);
        setWaiting();
        emailInputCallback(email, (msg) => {
            setSucceeded(msg);
        }, (msg) => {
            setFailed(msg);
        })
    };

    const WaitingSpinner = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <CircularProgress color="primary" />
            </Box>
        );
    };

    const FailInformation = () => {
        return (
            <Box sx={{ marginBottom: '1vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <Typography sx={{ marginBottom: '5vh', fontSize: '20px' }} variant="subtitle2" textAlign='center'>Das hat leider nicht geklappt: <br /> {status.msg}</Typography>
                <Button sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={setStart} fullWidth>Zurück</Button>
            </Box>
        );
    };

    const SuccessInformation = () => {
        return (
            <Box sx={{ marginBottom: '10vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <Typography sx={{ fontSize: '20px' }} variant="subtitle2" textAlign='center'>Das hat geklappt, Glückwunsch! <br /> {status.msg}</Typography>
            </Box>
        );
    };


    const getCurrentPageFromStatus = () => {
        if (status.status === "start") {
            return verify ? <VerifyInput withName={withName} password={password} password_validation={password_validation} passwordNotTheSame={passwordNotTheSame} name={name} setPassword={setPassword} setPassword_validation={setPassword_validation} password_too_weak={password_too_weak} setPasswordScore={(score) => setPasswordScore(score)} setName={setName} name_empty={name_empty} handleVerify={handleVerify} button_text={verify_button_text} /> : <InputFields email={email} setEmail={setEmail} handleEmailInput={handleEmailInput} email_empty={email_empty} button_text={email_input_button_text} />;
        }
        if (status.status === "waiting") {
            return <WaitingSpinner />;
        }
        if (status.status === "failed") {
            return <FailInformation />;
        }
        if (status.status === "succeeded") {
            return <SuccessInformation />;
        }
    };

    return (
        <>
            <FullScreenBackground withLines={breakpointmatches}>
                <Stack spacing='10vh' sx={{ width: { xs: '80vw', sm: '70vw', md: '50vw', lg: '40vw', xl: '40vw' } }}>
                    <Typography textAlign="center" variant="h4" sx={{ fontWeight: 'bold', color: '#ffffff' }}>Willkommen bei Glarity.</Typography>
                    <Paper elevation={1} sx={{ padding: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                        {getCurrentPageFromStatus()}
                    </Paper>
                </Stack>
            </FullScreenBackground>
        </>
    );
};

export default SetNewPassword;
