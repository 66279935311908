import React from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';

const UserTitleTypography = styled(Typography)(
    ({ theme }) => `
    font-weight: bold;
    margin-bottom: -5px;
`,);



const Headusers = () => {
    return (
        <div sx={{ flex: '5', width: '100%' }}>
            <Paper sx={{ marginTop: '30px', marginBottom: '30px', marginLeft: '40px', marginRight: '40px', padding: '20px' }} elevation={3}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: '-5px' }}>Alle Leiter</Typography>
                <Typography variant='subtitle2' sx={{ fontSize: '10px', color: 'grey' }}>6 Leiter</Typography>




                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
                <UserTitleTypography variant='h6'>Teilnehmer</UserTitleTypography>
            </Paper>
        </div>
    )
};

export default Headusers;
