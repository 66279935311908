import React from 'react';
import SetNewPassword from '../../components/SetNewPassword/SetNewPassword';
import { useAPI } from '../../contexts/Api';

const ForgotPassword = ({verify}) => {
    const api = useAPI();

    const verifyCallback = (email_token, name, password, onSuccess, onError) => {
        api.do_headuser_forgot_password_verify(email_token, password, (resp) => {
            onSuccess("Du kannst dich nun mit deinem neuen Passwort einloggen.");
        }, (error) => {
            onError(error.msg);
        });
    };

    const emailInputCallback = (email, onSuccess, onError) => {
        api.do_headuser_forgot_password(email, (resp) => {
            onSuccess("Um dein Passwort Reset abzuschließen, folge dem Link, den wir an " + email + " gesendet haben.")
        }, (error) => {
            onError(error.msg);
        });
    };
     
    return (
        <>
            <SetNewPassword verify={verify} withName={false} verifyCallback={verifyCallback} emailInputCallback={emailInputCallback} verify_button_text="Passwort Ändern" email_input_button_text="Passwort Zurücksetzen" />
        </>
    );
};

export default ForgotPassword;
