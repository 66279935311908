import React from 'react';
import { Stack, TextField, Button, Typography } from '@mui/material';


const InputFields = ({ email, setEmail, email_empty, handleEmailInput, button_text }) => {
    return (
        <Stack spacing={1}>
            <TextField error={email_empty} helperText={email_empty ? "Bitte geben Sie eine Email ein" : ""} id="email" label="Email" variant="filled" value={email} onChange={(event) => { setEmail(event.target.value) }} />
            <Button disabled={!email} sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={handleEmailInput} fullWidth>{button_text}</Button>
        </Stack>
    );
};

export default InputFields;
