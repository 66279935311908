import React, { useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, Paper, Stack, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import FullScreenBackground from '../../components/FullScreenBackground/FullScreenBackground';
import { useTheme } from '@mui/system';
import { useAPI } from '../../contexts/Api';
import { useStorage } from '../../contexts/Storage';
import { useNavigate, useLocation } from 'react-router';
import InputFields from './InputFields/InputFields';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { useAuth } from '../../contexts/Auth';


const Login = () => {
    const api = useAPI();
    const auth = useAuth();
    const storage = useStorage();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const breakpointmatches = useMediaQuery(theme.breakpoints.up('md'));

    const from = location.state?.from?.pathname || "/";

    const [forgot_email, setForgot_email] = useState("");
    const [forgot_email_empty, setForgot_email_empty] = useState(false);
    const [password, setPassword] = useState("");
    const [password_empty, setPassword_empty] = useState(false);
    const [email, setEmail] = useState("");
    const [email_empty, setEmail_empty] = useState(false);

    const [status, setStatus] = useState({ status: "start", msg: "" });
    const setFailed = (msg) => {
        setStatus({ status: "failed", msg: msg });
    };
    const setStart = () => {
        setStatus({ status: "start", msg: status.msg });
    }
    const setPasswordForgot = () => {
        setStatus({ status: "forgotPassword", msg: status.msg });
    }
    const setPasswordForgotFinished = () => {
        setStatus({ status: "forgotPasswordFinished", msg: status.msg });
    }
    const setWaiting = () => {
        setStatus({ status: "waiting", msg: "" });
    };

    useEffect(() => {
        api.check_auth_tokens((authenticated) => {
            console.log("check auth tokens login");
            auth.setAuthenticated(authenticated);
            if (authenticated) {
                navigate(from, { replace: true });
            }
        })
    });


    const handleLogIn = () => {
        if (email === "") {
            setEmail_empty(true);
            return;
        }
        setEmail_empty(false);
        if (password === "") {
            setPassword_empty(true);
            return;
        }
        setPassword_empty(false);
        setWaiting();
        api.do_admin_login(email, password, (resp) => {
            storage.updateStorage(resp);
            auth.setAuthenticated(true);
            navigate(from, { replace: true });
        }, (error) => {
            setFailed(error.msg);
        });
    };


    const onPasswordForgot = () => {
        setPasswordForgot();
    };

    const handleResetPassword = () => {
        if (forgot_email === "") {
            setForgot_email_empty(true);
            return;
        }
        setWaiting();
        console.log("do reset");
        //TODO: handle real reset password
        // TODO: maybe add recaptcha to prevent robots
        setTimeout(() => {
            setPasswordForgotFinished();
        }, 2000);
    };

    const WaitingSpinner = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <CircularProgress color="primary" />
            </Box>
        );
    };


    const FailInformation = () => {
        return (
            <Box sx={{ marginBottom: '1vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <Typography sx={{ marginBottom: '5vh', fontSize: '20px' }} variant="subtitle2" textAlign='center'>Das hat leider nicht geklappt: <br /> {status.msg}</Typography>
                <Button sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={setStart} fullWidth>Zurück</Button>
            </Box >
        );
    };

    const ForgotPasswordFinished = () => {
        return (
            <Box sx={{ marginBottom: '1vh', marginTop: '10vh', marginLeft: '1vw', marginRight: '1vw' }}>
                <Typography sx={{ marginBottom: '5vh', fontSize: '20px' }} variant="subtitle2" textAlign='center'>Das hat geklappt, du bekommst dein neues Passwort per Post zugeschickt <br /> {status.msg}</Typography>
                <Button sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={setStart} fullWidth>OK</Button>
            </Box >
        );
    };


    const getCurrentPageFromStatus = () => {
        if (status.status === "start") {
            return <InputFields email={email} setEmail={setEmail} email_empty={email_empty} password={password} setPassword={setPassword} password_empty={password_empty} handleLogIn={handleLogIn} onPasswordForgot={onPasswordForgot} />;
        }
        if (status.status === "waiting") {
            return <WaitingSpinner />;
        }
        if (status.status === "failed") {
            return <FailInformation />;
        }
        if (status.status === "forgotPassword") {
            return <ForgotPassword forgot_email={forgot_email} forgot_email_empty={forgot_email_empty} setForgotEmail={setForgot_email} setStart={setStart} handleResetPassword={handleResetPassword} />;
        }
        if (status.status === "forgotPasswordFinished") {
            return <ForgotPasswordFinished />;
        }
    };

    return (
        <>
            <FullScreenBackground withLines={breakpointmatches}>
                <Stack spacing='10vh' sx={{ width: { xs: '80vw', sm: '70vw', md: '50vw', lg: '40vw', xl: '40vw' } }}>
                    <Typography textAlign="center" variant="h4" sx={{ fontWeight: 'bold', color: '#ffffff' }}>Willkommen bei Glarity.</Typography>
                    <Paper elevation={1} sx={{ padding: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                        {getCurrentPageFromStatus()}
                    </Paper>
                </Stack>
            </FullScreenBackground>
        </>
    );
};

export default Login;
