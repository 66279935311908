import React from 'react';

const License = () => {
    return (
        <div>
            Lizenz
        </div>
    );
};

export default License;
