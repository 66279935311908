import { createContext, useContext } from 'react';




const updateStorage = (storage) => {
    navigator.serviceWorker.controller.postMessage({
        action: 'UPDATE_STORAGE',
        storage: storage,
    });
}


const StorageContext = createContext(null);


const StorageProvider = ({ children }) => {


    const value = { updateStorage };
    return (
        <StorageContext.Provider value={value}>
            {children}
        </StorageContext.Provider>
    )
};


function useStorage() {
    return useContext(StorageContext);
}


export { StorageProvider, useStorage };