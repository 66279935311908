import { createContext, useContext, useState } from 'react';



const AuthContext = createContext(null);


const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticatedState] = useState(false);

    console.log("auth updated");

    const setAuthenticated = (auth) => {
        console.log("set auth to : " + auth); // TODO: inspect state deadlock
        setAuthenticatedState(auth);
    }

    const value = { authenticated, setAuthenticated };
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
};


function useAuth() {
    return useContext(AuthContext);
}


export { AuthProvider, useAuth };