import { createTheme, responsiveFontSizes, rgbToHex } from "@mui/material";



const theme = (mode) => (responsiveFontSizes(createTheme({
    spacing: 8,
    palette: {
        mode: mode,
        ...(mode === "light" ? {
            primary: {
                main: "#2666fb",
                contrastText: "#ffffff",
            },
            secondary: {
                main: "#00c2fe",
                contrastText: "#ffffff",
            },
            text: {
                primary: "#000000",
                secondary: "#a1aabd",
            },
            background: {
                pagecontainer: "#f2f2f2",
                appbar: "#ffffff",
                paper: "#ffffff",
                default: "#f2f2f2",
            },
        } : {
            primary: {
                main: "#ffffff",
            },
            secondary: {
                main: "#ffffff",
            },
            text: {
                primary: "#000000",
                secondary: "#a1aabd",
            },
            background: {
                pagecontainer: "#f2f2f2",
                appbar: "#ffffff",
                paper: "#121212",
                default: "#121212",
            },
        }),
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Robot',
        ].join(','),
    },
})));


export default theme;