import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import GlarityButton from '../GlarityButton/GlarityButton';
import { Typography, AppBar, Drawer, Toolbar, Grid, IconButton } from '@mui/material';
import { useAPI } from '../../contexts/Api.jsx';
import { useAuth } from '../../contexts/Auth.jsx';
import MenuDrawer from './MenuDrawer/MenuDrawer.jsx';
import { styled } from '@mui/system';


const AppBarCSS = styled(AppBar)(
    ({ theme }) => `
    z-index: ${theme.zIndex.drawer + 1};
    background-color: ${theme.palette.background.appbar};
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
`,);


const DrawerCSS = styled(Drawer)(
    ({ theme }) => `
    ${theme.breakpoints.down('sm')} {
        display: none;
    };
    height: 100%;
    width: 200px;
    flex-shrink: 0;
    & .MuiDrawer-paper {
        width: 200px;
        box-sizing: border-box;
    };
`,);


const TempDrawerCSS = styled(Drawer)(
    ({ theme }) => `
    ${theme.breakpoints.up('sm')} {
        display: none;
    };
    height: 100%;
    width: 200px;
    flex-shrink: 0;
    & .MuiDrawer-paper {
        width: 200px;
        box-sizing: border-box;
    };
`,);


const IconButtonCSS = styled(IconButton)(
    ({ theme }) => `
    ${theme.breakpoints.down('sm')} {
        display: inline;
    };
    display: none;
`,);

const LogoutButtonCSS = styled(GlarityButton)(
    ({ theme }) => `
    ${theme.breakpoints.down('sm')} {
        display: none;
    };
    display: inline;
`,);



const Navigation = ({ handleThemeChange }) => {
    const auth = useAuth();
    const api = useAPI();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const handleLogout = () => {
        api.do_logout((resp) => {
            auth.setAuthenticated(false);
        }, (error) => {
            console.log("error in logout: ", error);
        });
    };


    return (
        <>
            <AppBarCSS position='fixed'>
                <Toolbar>
                    <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                        <Grid item>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '30px', cursor: 'pointer' }} variant="h5" color="textPrimary">Glarity</Typography>
                        </Grid>
                        <Grid item>
                            <IconButtonCSS onClick={toggleDrawer(true)}>
                                <MenuIcon />
                            </IconButtonCSS>
                            <IconButtonCSS onClick={handleLogout}>
                                <LogoutIcon />
                            </IconButtonCSS>
                            <LogoutButtonCSS size='small' variant="contained" disableElevation onClick={handleLogout}>
                                <Typography sx={{ fontWeight: 'bold' }} variant="button">Ausloggen</Typography>
                            </LogoutButtonCSS>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBarCSS>
            <DrawerCSS variant="permanent">
                <MenuDrawer toggleDrawer={toggleDrawer} handleThemeChange={handleThemeChange} />
            </DrawerCSS>
            <TempDrawerCSS variant="temporary" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <MenuDrawer toggleDrawer={toggleDrawer} handleThemeChange={handleThemeChange} />
            </TempDrawerCSS>
        </>
    )
}

export default Navigation;
