import { createContext, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useStorage } from './Storage';


//const api_base = "http://192.168.0.152:5000/v1/";
//const api_base = "http://localhost:5000/v1/";
const api_base = "https://glarity-server-qz92j.ondigitalocean.app/v1/";
const content_type_json = { 'Content-Type': "application/json" };

var globalTaskCounter = 0;
const globalCallbackTable = {};

var worker = new Worker("/authworker.js");

var check_auth_tokens_callback = () => { };


navigator.serviceWorker.addEventListener('message', event => {
    const action = event.data.action;
    if (action === "CHECK_AUTH_TOKENS") {
        check_auth_tokens_callback(event.data.response);
    }
});

const check_auth_tokens = (callback) => {
    if (!navigator.serviceWorker.controller) return;
    check_auth_tokens_callback = callback;
    navigator.serviceWorker.controller.postMessage({ action: "CHECK_AUTH_TOKENS" });
}



const is_failed = (resp) => {
    if ("status" in resp) {
        return resp.status === "failed";
    }
    return true;
}

worker.onmessage = function (e) {
    if ("task_id" in e.data) {
        if (e.data.status === "succeeded") {
            globalCallbackTable[e.data.task_id].onsuccess(e.data.response);
        } else {
            globalCallbackTable[e.data.task_id].onerror(e.data.response);
        }
        delete e.data.task_id;
    }
}


const do_admin_login = async (admin_email, admin_password, onsuccess, onerror) => {
    var task_id = globalTaskCounter++;
    worker.postMessage({ task_id: task_id, action: 'ADMIN_LOGIN', admin_email: admin_email, admin_password: admin_password });
    globalCallbackTable[task_id] = { onsuccess: onsuccess, onerror: onerror };
};

const do_headuser_registration = async (email, onsuccess, onerror) => {
    var task_id = globalTaskCounter++;
    worker.postMessage({ task_id: task_id, action: 'REGISTRATION_REQUEST', email: email });
    globalCallbackTable[task_id] = { onsuccess: onsuccess, onerror: onerror };
};

const do_headuser_registration_verify = async (email_token, name, password, onsuccess, onerror) => {
    var task_id = globalTaskCounter++;
    worker.postMessage({ task_id: task_id, action: 'REGISTRATION_VERIFY', token: email_token, name: name, password: password });
    globalCallbackTable[task_id] = { onsuccess: onsuccess, onerror: onerror };
};

const do_headuser_forgot_password = async (email, onsuccess, onerror) => {
    var task_id = globalTaskCounter++;
    worker.postMessage({ task_id: task_id, action: 'HEADUSER_FORGOT_PASSWORD_REQUEST', email: email });
    globalCallbackTable[task_id] = { onsuccess: onsuccess, onerror: onerror };
};

const do_headuser_forgot_password_verify = async (email_token, password, onsuccess, onerror) => {
    var task_id = globalTaskCounter++;
    worker.postMessage({ task_id: task_id, action: 'HEADUSER_FORGOT_PASSWORD_VERIFY', token: email_token, password: password });
    globalCallbackTable[task_id] = { onsuccess: onsuccess, onerror: onerror };
};



const APIContext = createContext(null);


const APIProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const storage = useStorage();

    const fetch_wrapper = (url, options) => {
        return new Promise(function (resolve, reject) {
            fetch(url, options).then((resp) => {
                if (resp.status === 403 || resp.status === 401) {
                    navigate("/login", { replace: true, state: { from: location } });
                    reject(resp);
                }
                resolve(resp);
            }).catch((error) => {
                console.log("fetch wrapper error: " + error);
                reject(error);
            })
        });
    };


    const do_logout = async (onsuccess, onerror) => {
        console.log("logout");
        fetch_wrapper(api_base + "logout", {
            method: "GET",
        }).then(resp => resp.json()).then((resp) => {
            if (is_failed(resp)) {
                onerror(resp);
            }
            storage.updateStorage({ access_token: "", refresh_token: "" });
            onsuccess(resp);
        }).catch((error) => {
            onerror(error);
        })
    };



    const value = { fetch_wrapper, check_auth_tokens, do_logout, do_admin_login, do_headuser_registration, do_headuser_registration_verify, do_headuser_forgot_password, do_headuser_forgot_password_verify };
    return (
        <APIContext.Provider value={value}>
            {children}
        </APIContext.Provider>
    )
};


function useAPI() {
    return useContext(APIContext);
}


export { APIProvider, useAPI };