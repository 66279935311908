import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';


const ButtonCSS = styled(Button)(
    ({ theme }) => `
    border-radius: 5px;
    :hover {
        background: #ffffff;
        color: #200010;
    }
    :disabled {
        background: #e2e3e5;
        color: #200010;
    }
`,);


const GlarityButton = ({ children, spinning, ...rest }) => {
    return (
        <>
            <ButtonCSS {...rest}>

                {spinning ? (
                    <CircularProgress size={24} sx={{ position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px" }} />
                ) : (
                    children
                )}
            </ButtonCSS>
        </>
    )
};

export default GlarityButton;
