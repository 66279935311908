import React from 'react';
import { Toolbar, Box, List, ListItem, ListItemText, Switch } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';


const MenuDrawer = ({ toggleDrawer, handleThemeChange }) => {
    const theme = useTheme();


    return (
        <>
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List dense component="nav">
                    <ListItem button component={Link} to="/account" onClick={toggleDrawer(false)}>
                        <ListItemText primary="Manage Account" />
                    </ListItem>
                    <ListItem button component={Link} to="/groups" onClick={toggleDrawer(false)}>
                        <ListItemText primary="Gruppen" />
                    </ListItem>
                    <ListItem button component={Link} to="/users" onClick={toggleDrawer(false)}>
                        <ListItemText primary="Teilnehmer / Leiter" />
                    </ListItem>
                    <ListItem button component={Link} to="/license" onClick={toggleDrawer(false)}>
                        <ListItemText primary="Lizenz" />
                    </ListItem>
                </List>
                <List sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
                    <ListItem >
                        <ListItemText id="switch-list-label-dark-mode" primary="Dark Mode" onClick={handleThemeChange} />
                        <Switch size="small" edge="end" checked={theme.palette.mode === "dark"} key={theme.palette.mode} onChange={handleThemeChange} inputProps={{ 'aria-labelledby': 'switch-list-label-dark-mode' }} />
                    </ListItem>
                </List>
            </Box>
        </>
    )
}

export default MenuDrawer;
