import React from 'react';
import backLogInPNG from '../../assets/backLogIn.png';
import backLogInSVG from '../../assets/backLogIn.svg';
import backLogIn from '../../assets/backLogIn.webp';
import linesLogInPNG from '../../assets/linesLogIn.png';
import linesLogIn from '../../assets/linesLogIn.webp';
import { Box } from '@mui/material';
import { styled } from '@mui/system';


const ImageBox = styled(Box)(
    ({ theme }) => `
    background-color: ${theme.palette.primary.main};
    min-height: 100vh;
    min-width: 100vw;
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    justifiy-content: center;
    align-items: center;
    z-index: -1;
`,);

const OverlayBox = styled(Box)(
    ({ theme }) => `
    margin-top: 5vh;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`,);


const FullScreenBackground = ({ children, withLines }) => {
    const backgroundImage = withLines ? `url(${linesLogIn}), url(${backLogIn})` : `url(${backLogIn})`;
    const backgroundPosition = withLines ? 'top, bottom' : "bottom";
    const backgroundSize = withLines ? 'cover, contain' : "contain";

    return (
        <ImageBox sx={{ display: 'block', backgroundImage: backgroundImage, backgroundPosition: backgroundPosition, backgroundSize: backgroundSize }}>
            <OverlayBox>
                {children}
            </OverlayBox>
        </ImageBox>
    );
};

export default FullScreenBackground;
