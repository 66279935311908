import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { useAPI } from '../../contexts/Api';
import { useAuth } from '../../contexts/Auth';


const RequireAuth = () => {
    const auth = useAuth();
    const api = useAPI();
    const navigate = useNavigate();
    const location = useLocation();


    api.check_auth_tokens((authenticated) => {
        if (!authenticated) {
            auth.setAuthenticated(false);
            console.log("navigate from require auth to login");
            navigate("/login", { replace: true, state: { from: location } });
        } else {
            if (!auth.authenticated) {
                console.log("setAuthenticated to true");
                auth.setAuthenticated(true);
            }
        }
    });


    return (
        <>
            {auth.authenticated && <Outlet />}
        </>
    );
};

export default RequireAuth;
