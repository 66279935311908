import React from 'react';
import {Box, Toolbar} from '@mui/material';
import { useTheme } from '@emotion/react';
import Navigation from '../Navigation/Navigation';


const DashboardLayout = ({ children, darkMode, handleThemeChange }) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex' }}>
          <Navigation darkMode={darkMode} handleThemeChange={handleThemeChange} />

          <Box component="main" sx={{ flexGrow: 1, p: 3, padding: '0px', minHeight: '100vh', backgroundColor: theme.palette.background.pagecontainer }}>
            <Toolbar />
            {children}
          </Box>
        </Box>  
    );
}

export default DashboardLayout;
