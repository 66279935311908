import React from 'react';
import { Stack, TextField, Button, Typography, Box } from '@mui/material';


const InputFields = ({ email, setEmail, email_empty, password, setPassword, password_empty, handleLogIn, onPasswordForgot }) => {
    return (
        <Stack spacing={1}>
            <TextField error={email_empty} helperText={email_empty ? "Bitte geben Sie eine Email ein" : ""} id="email" label="Email" variant="filled" value={email} onChange={(event) => { setEmail(event.target.value); }} />
            <TextField error={password_empty} helperText={password_empty ? "Bitte geben Sie ein Passwort ein" : ""} id="password" label="Passwort" variant="filled" inputRef={x => password = x} type="password" value={password} onChange={(event) => { setPassword(event.target.value); }} />
            <Button sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={handleLogIn}>Einloggen</Button>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <Typography variant="body2" sx={{":hover": {cursor: "pointer", color: "primary.main"}}} onClick={onPasswordForgot}>Passwort vergessen?</Typography>
            </Box>
        </Stack>
    );
};

export default InputFields;
