import React from 'react';

const Account = () => {
    return (
        <div>
            Manage Account
        </div>
    );
};

export default Account;
