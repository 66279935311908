import { Box, ThemeProvider, CssBaseline, useMediaQuery, Toolbar } from '@mui/material';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Navigation from './components/Navigation/Navigation';
import Account from './pages/Account/Account';
import Headusers from './pages/Headusers/Headusers';
import Groups from './pages/Groups/Groups';
import License from './pages/License/License';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import NotFound from './pages/NotFound/NotFound';
import { useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import makeTheme from './themes/app_theme';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import { APIProvider } from './contexts/Api';
import { StorageProvider } from './contexts/Storage';
import { AuthProvider } from './contexts/Auth';
import DashboardLayout from './components/DashboardLayout/DashboardLayout';

function App() {
  const [darkMode, setDarkMode] = useState(useMediaQuery("(prefers-color-scheme: dark)"));

  const theme = useMemo(
    () =>
      makeTheme(darkMode ? "dark" : "light"),
    [darkMode],
  );

  const handleThemeChange = () => {
    setDarkMode((prevMode) => (!prevMode));
  };

  return (
    <div>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <StorageProvider>
          <APIProvider>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot_password">
                  <Route index element={<ForgotPassword />} />
                  <Route path="verify">
                    <Route path=":email_token" element={<ForgotPassword verify />} />
                  </Route>
                </Route>
                <Route path="/register">
                  <Route index element={<Register />} />
                  <Route path="verify">
                    <Route path=":email_token" element={<Register verify />} />
                  </Route>
                </Route>
                <Route path="/" element={<RequireAuth />}>
                  <Route index element={<DashboardLayout darkMode={darkMode} handleThemeChange={handleThemeChange}><Account /></DashboardLayout>} />
                  <Route path="account" element={<DashboardLayout darkMode={darkMode} handleThemeChange={handleThemeChange}><Account /></DashboardLayout>} />
                  <Route path="groups" element={<DashboardLayout darkMode={darkMode} handleThemeChange={handleThemeChange}><Groups /></DashboardLayout>} />
                  <Route path="users" element={<DashboardLayout darkMode={darkMode} handleThemeChange={handleThemeChange}><Headusers /></DashboardLayout>} />
                  <Route path="license" element={<DashboardLayout darkMode={darkMode} handleThemeChange={handleThemeChange}><License /></DashboardLayout>} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AuthProvider>
          </APIProvider>
        </StorageProvider>
      </ThemeProvider>
    </div >
  );
}

export default App;
