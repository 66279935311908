import React from 'react';
import { Stack, TextField, Button } from '@mui/material';
import PasswordStrengthBar from 'react-password-strength-bar';


const VerifyInput = ({ withName, password, password_validation, password_too_weak, setPasswordScore, passwordNotTheSame, name, setPassword, setPassword_validation, setName, name_empty, handleVerify, button_text }) => {
    return (
        <Stack spacing={1}>
            {
                withName && <TextField error={name_empty} helperText={name_empty ? "Bitte geben Sie einen Namen ein" : ""} id="name" label="Name" variant="filled" value={name} onChange={(event) => { setName(event.target.value) }} />
            }
            <TextField error={password_too_weak} helperText={password_too_weak ? "Passwort ist zu schwach" : ""} id="password" label="Passwort" variant="filled" type="password" value={password} onChange={(event) => { setPassword(event.target.value) }} />
            <TextField error={passwordNotTheSame} helperText={passwordNotTheSame ? "Passwörter müssen übereinstimmen" : ""} id="password-validation" label="Passwort Bestätigen" variant="filled" type="password" value={password_validation} onChange={(event) => { setPassword_validation(event.target.value) }} />
            <PasswordStrengthBar password={password} onChangeScore={(score, feedback) => {
                setPasswordScore(score);
            }} />
            <Button sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={handleVerify} fullWidth>{button_text}</Button>
        </Stack>
    );
};

export default VerifyInput;
