import React from 'react';
import { Stack, Typography, Box, Button, TextField } from '@mui/material';

const ForgotPassword = ({forgot_email_empty, forgot_email, setForgotEmail, setStart, handleResetPassword}) => {
    return (
        <Box sx={{ marginBottom: '0vh', marginTop: '2vh', marginLeft: '1vw', marginRight: '1vw' }}>
            <Typography align="center" variant="body1">Um dein Passwort zurückzusetzen, gib bitte deine Email an.</Typography>
            <Stack spacing={1} sx={{marginTop: "1rem"}}>
                <TextField error={forgot_email_empty} helperText={forgot_email_empty ? "Bitte geben Sie eine Email ein" : ""} id="forgotemail" label="Email" variant="filled" value={forgot_email} onChange={(event) => { setForgotEmail(event.target.value); }} />
                <Button disabled={!forgot_email} sx={{ minHeight: '40px', height: { xs: '7vh', sm: '5vh', md: '4.5vh', lg: '5vh', xl: '5vh' }, fontWeight: 'bold' }} variant="contained" color="secondary" onClick={handleResetPassword} fullWidth>Passwort Zurücksetzen</Button>
            </Stack>
            <Box sx={{display: "flex", justifyContent: "flex-start", marginTop: "2vh"}}>
                <Typography variant="body1" sx={{':hover': {cursor: "pointer", color: "primary.main"}}} onClick={setStart}>Zurück</Typography>
            </Box>
        </Box>
    );
}

export default ForgotPassword;
